import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import StravaContext from '../../context/StravaContext'
import ChartWrapper from '../visuals/ChartWrapper'
import {Circles} from 'react-loader-spinner';
import Tile from './Tile'

const StravaContainer = () => {
  const {
    stravaActivities,
    reAuthorize,
    setStravaActivities,
    stravaTotals,
    setStravaTotals,
  } = useContext(StravaContext)

  const [error, setError] = useState(false)

  useEffect(() => {
    const stravaDataMessage = JSON.parse(localStorage.getItem('stravaData'))?.message;
    if(stravaDataMessage === 'Rate Limit Exceeded') setError('Rate Limit Exceeded even though cache is in place. Try back later.');
    if(!localStorage.getItem('stravaData') || !localStorage.getItem('stravaTotals')) {
      reAuthorize()
    }
  }, [])

  if (stravaActivities && stravaTotals && !error) {
    return (
      <>
        <ChartWrapper />

        <div className="d-grid tile--grid">
          <Tile type={'Run'} />
          <Tile type={'Ride'} />
          <Tile type={'WeightTraining'} />
          <Tile type={'Hike'} />
          <Tile type={'Walk'} />
        </div>
        {/* <Table responsive striped bordered>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {stravaActivities.map((activity, index) => {
              return (
                <StravaActivity key={index} activity={activity} index={index} />
              )
            })}
          </tbody>
        </Table> */}
      </>
    )
  } else {
    return (
      <>
        {error && <h2>{error}</h2> }
        {!error && 
          <Circles
            wrapperClass="circles-loading"
            height="80"
            width="80"
            color="#005dab"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            visible={true}
          />
        }
      </>
    )
  }
}

export default StravaContainer
