import * as d3 from 'd3'
import moment from 'moment'
import { monthNames } from '../utils/utils'

const COLORS = { male: '#89CFF0', female: '#f4c2c2' }
const CHART_MARGIN = { top: 100, right: 10, bottom: 170, left: 70 }
const CHART_WIDTH = 800 - CHART_MARGIN.right - CHART_MARGIN.left
const CHART_HEIGHT = 500 - CHART_MARGIN.top - CHART_MARGIN.bottom
const CHART_XAXIS_LABEL = 'Date'
const CHART_YAXIS_LABEL = 'Hours of Activity'

export default class D3Chart {
  constructor(element, stravaActivities) {
    // ACTIVITIES > FORMAT DATE
    stravaActivities.reverse()
    stravaActivities.map((activity) => {
      let aStartDate = new Date(activity.start_date)
      activity.start_date_formatted =
        monthNames[moment(aStartDate).format('M') - 1] +
        ' ' +
        moment(aStartDate).format('YYYY')
    })

    // ACTIVITIES > GROUP
    const stravaActivitiesGrouped = d3.rollup(
      stravaActivities,
      (v) => d3.sum(v, (d) => d.elapsed_time),
      (d) => d.start_date_formatted
    )

    // format data
    var dataByMonth = []
    Array.from(stravaActivitiesGrouped, ([key, value]) => {
      dataByMonth.push({
        start_date: key,
        elapsed_time: value,
      })
    })

    stravaActivities = dataByMonth

    const sortByDateAscending = (a, b) => {
      return moment(b.start_date_formatted) - moment(a.start_date_formatted)
    }

    stravaActivities.sort(sortByDateAscending)

    const vis = this

    vis.activities = stravaActivities

    vis.svg = d3
      .select(element)
      .append('svg')
      .attr(
        'viewBox',
        `0 0 ${CHART_WIDTH + CHART_MARGIN.right + CHART_MARGIN.left} ${
          CHART_HEIGHT + CHART_MARGIN.top + CHART_MARGIN.bottom
        }`
      )
      .attr('width', CHART_WIDTH + CHART_MARGIN.right + CHART_MARGIN.left)
      .attr('height', CHART_HEIGHT + CHART_MARGIN.top + CHART_MARGIN.bottom)
      .append('g')
      .attr('transform', `translate(${CHART_MARGIN.left}, ${CHART_MARGIN.top})`)

    vis.xLabel = vis.svg
      .append('text')
      .attr('x', CHART_WIDTH / 2)
      .attr('y', CHART_HEIGHT + 100)
      .attr('fill', 'black')
      .attr('text-anchor', 'middle')
      .text(CHART_XAXIS_LABEL)

    vis.yLabel = vis.svg
      .append('text')
      .attr('x', -(CHART_HEIGHT / 2))
      .attr('y', -50)
      .attr('text-anchor', 'middle')
      .text(CHART_YAXIS_LABEL)
      .attr('transform', 'rotate(-90)')

    vis.xAxisGroup = vis.svg
      .append('g')
      .attr('transform', `translate(0, ${CHART_HEIGHT})`)

    vis.yAxisGroup = vis.svg.append('g')

    const y = d3
      .scaleLinear()
      .domain([
        d3.min(vis.activities, (d) => d.elapsed_time / 60 / 60) * 0.95,
        d3.max(vis.activities, (d) => d.elapsed_time / 60 / 60),
      ])
      .range([CHART_HEIGHT, 0])

    const x = d3
      .scaleBand()
      .domain(vis.activities.map((d) => d.start_date))
      .range([0, CHART_WIDTH])
      .padding(0.4)

    const xAxisCall = d3.axisBottom(x).ticks(6)

    vis.xAxisGroup
      .transition()
      .duration(1500)
      .call(xAxisCall)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')

    const yAxisCall = d3.axisLeft(y)
    vis.yAxisGroup.transition().duration(1500).call(yAxisCall)

    // DATA JOIN
    const rects = vis.svg.selectAll('rect').data(vis.activities)

    rects
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(d.start_date))
      .attr('width', x.bandwidth)
      .attr('y', CHART_HEIGHT)
      .attr('fill', '#005dab')
      .transition()
      .duration(1500)
      .attr('height', (d) => CHART_HEIGHT - y(d.elapsed_time / 60 / 60))
      .attr('y', (d) => y(d.elapsed_time / 60 / 60))
  }
}
