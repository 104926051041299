import React, { useRef, useEffect } from 'react'
import D3Chart from './D3Chart'
import { Dropdown } from 'react-bootstrap'
import { useContext } from 'react'
import StravaContext from '../../context/StravaContext'
import Example from '../Example'

const ChartWrapper = (props) => {
  const { stravaActivities } = useContext(StravaContext)
  const [storedChart, setStoredChart] = React.useState(true)
  const chart = useRef()

  const [genderSelected, setGenderSelected] = React.useState('male')

    useEffect(() => {
      if (!chart.current.children || chart.current.children.length === 0) {
        setStoredChart(new D3Chart(chart.current, stravaActivities))
      } else {
        storedChart.update(genderSelected)
      }
    }, [chart, genderSelected])

  const CHART_STYLES = {
    display: 'flex',
    justifyContent: 'center',
  }

  return (
    <>
      <div
        id="chart"
        className="shadow-2xl"
        ref={chart}
        style={CHART_STYLES}
      ></div>
    </>
  )
}

export default ChartWrapper
