import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import Layout from '../components/layout'
import { Container, Row, Col, Table } from 'react-bootstrap'
import StravaContainer from '../components/strava/StravaContainer'

const Strava = (props) => {
  const pageData = props.data.contentfulPage

  return (
      <Layout location={props.location}>
        <Seo
          title="Strava | Adam Doe"
          description="nothin"
          pathname={pageData.slug}
          article
        />

        <Container style={{ margintTop: '50px', marginBottom: '50px' }} className="strava">
          <Row>
            <Col xs={12}>
              <StravaContainer />
            </Col>
          </Row>
        </Container>
      </Layout>
  )
}

export const pageQuery = graphql`
  query StravaQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
        }
      }
    }
    contentfulPage(slug: { eq: "/" }, image: { file: { url: {} } }) {
      id
      slug
      heading
      image {
        id
        file {
          url
        }
      }
      body {
        raw
      }
    }
  }
`

export default Strava
