export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const activityNames = {
  WeightTraining: 'Weight Lifting',
  Ride: 'Cycling',
  Workout: 'Cardio',
  Hike: 'Hike',
  Run: 'Run',
  Walk: 'Walk',
}
