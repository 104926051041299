import React, { useContext, useEffect } from 'react'
import StravaContext from '../../context/StravaContext'
import moment from 'moment'
import { activityNames } from '../utils/utils'

const Tile = ({ type }) => {
  const {
    stravaActivities,
    stravaTotals,
  } = useContext(StravaContext)

  console.log('STRAVA TOTALS', stravaTotals)

  if(!stravaActivities) return;

  const filtered = stravaActivities?.filter((activity) => {
    return activity.type === type
  })

  const filteredAndReducedTime = filtered?.reduce((prev, current) => {
    return prev + current.elapsed_time
  }, 0)

  const filteredCount = filtered.length

  const formattedTime = moment
    .utc(filteredAndReducedTime * 1000)
    .format('HH:mm:ss')

  const getMiles = (i) => {
    return i * 0.000621371192
  }

  const rideTimeTotals = moment
    .utc(stravaTotals?.all_ride_totals?.elapsed_time * 1000)
    .format('HH:mm:ss')

  const rideDistanceTotals = Math.trunc(
    getMiles(stravaTotals?.all_ride_totals?.distance)
  )

  const runTimeTotals = moment
    .utc(stravaTotals?.all_run_totals?.elapsed_time * 1000)
    .format('HH:mm:ss')

  const runDistanceTotals = Math.trunc(
    getMiles(stravaTotals?.all_run_totals?.distance)
  )
  
  if(stravaTotals && stravaActivities) {
    switch (type) {
      case 'Ride':
        return (
          <div className="tile bg-gradient shadow-2xl br-25">
            <h2>{activityNames[type]} Totals</h2>
            <p>Count: {stravaTotals?.all_ride_totals?.count}</p>
            <p>Time: {rideTimeTotals}</p>
            <p>Distance: {rideDistanceTotals} miles</p>
          </div>
        )
      case 'Run':
        return (
          <div className="tile bg-gradient shadow-2xl br-25">
            <h2>{activityNames[type]} Totals</h2>
            <p>Count: {stravaTotals?.all_run_totals?.count}</p>
            <p>Time: {runTimeTotals}</p>
            <p>Distance: {runDistanceTotals} miles</p>
          </div>
        )
      case 'WeightTraining':
        return (
          <div className="tile bg-gradient shadow-2xl br-25">
            <h2>{activityNames[type]} Totals</h2>
            <p>Count: {filteredCount}</p>
            <p>Time: {formattedTime}</p>
          </div>
        )
      case 'Hike':
        return (
          <div className="tile bg-gradient shadow-2xl br-25">
            <h2>{activityNames[type]} Totals</h2>
            <p>Count: {filteredCount}</p>
            <p>Time: {formattedTime}</p>
          </div>
        )
      case 'Walk':
        return (
          <div className="tile bg-gradient shadow-2xl br-25">
            <h2>{activityNames[type]} Totals</h2>
            <p>Count: {filteredCount}</p>
            <p>Time: {formattedTime}</p>
          </div>
        )
      default:
        return 'No type passed'
    }
  } else {
    console.error('Error loading tiles')
  }

}

export default Tile
